/** @jsx jsx */

import MySEO from '../components/seo'
import Link from '../components/Link'
import Layout from '../components/layout'
import {jsx,Flex,Container,Box,Heading,Text} from 'theme-ui'

export default function Mitglieder(){
  return(
    <Layout>
    <Flex>
<Container sx={{p:1,m:0,flexDirection:`column`}}>

   <MySEO title="Die Mitglieder des Vereins" description="Mitgliedsliste des Kulturvereins" />

 <Box sx={{maxWith:600,p:0,pb:90}} >
   <Heading sx={{fontWeight:500}} as="h1">Mitglieder</Heading>


<Text sx={{marginBottom:"0.6rem"}}>1. Vorsitzende: Katrin Gloggengiesser, Kommunikationsdesignerin &amp; Leitung Stadtmarketing Sankt Goar  // Bacharach</Text> 
<Text sx={{marginBottom:"0.6rem"}}>2. Vorsitzende: Prof. Dr. Susanne Enderwitz, Islamwissenschaftlerin  // Dörscheid</Text>
<Text sx={{marginBottom:"0.6rem"}}>Schatzmeister: Werner Reiter, Regierungsdirektor a.D.  // Bacharach</Text>
<Text sx={{marginBottom:"0.6rem"}}>
Dr. Dagmar Aversano-Schreiber, Historikerin // Sankt Goarshausen
</Text>
<Text sx={{marginBottom:"0.6rem"}}>René Broich, Programmierer  // Bacharach</Text>
<Text sx={{marginBottom:"0.6rem"}}>Elke Bussmann, Senior Sales Managerin  // Sankt Goar</Text>
<Text sx={{marginBottom:"0.6rem"}}>
Dr. Klaus Bussmann, Biologe // Sankt Goar
</Text>    
 <Text sx={{marginBottom:"0.6rem"}}>Michael Frey, Komponist, Musiker, Veranstalter</Text>  
<Text sx={{marginBottom:"0.6rem"}}>Vivi Hasse, Architektin   // Manubach </Text>  
<Text sx={{marginBottom:"0.6rem"}}>Walla Heldermann, Theater-Regisseurin // Koblenz</Text>  

<Text sx={{marginBottom:"0.6rem"}}>Dieter Horn, Fotograf   // Eisenach </Text>  
<Text sx={{marginBottom:"0.6rem"}}>Falko Hönisch, Opern- und Konzertsänger &amp; Stadtbürgermeister von Sankt Goar //  Sankt Goar</Text>  

<Text sx={{marginBottom:"0.6rem"}}>Peter Keber, Rechtsanwalt // Bacharach</Text>
<Text sx={{marginBottom:"0.6rem"}}>Dieter Kemmer, Marketing &amp; Sales Manager bei Böhringer // Ingelheim</Text>
 <Text sx={{marginBottom:"0.6rem"}}>Detlev Kleinen, Steinkunst // Sankt Goarshausen</Text> <Text sx={{marginBottom:"0.6rem"}}>Barbara Kölges, Landesbibliothek Koblenz // Sankt Goar</Text>
<Text sx={{marginBottom:"0.6rem"}}>Prof. Dr. Dieter Kramer, Kulturwissenschaftler // Dörscheid</Text>
<Text sx={{marginBottom:"0.6rem"}}>Rolf Merg, Unternehmer  // Bacharach</Text>
<Text sx={{marginBottom:"0.6rem"}}>Kent Michaelis, Denkmalschützer  // Boppard</Text>
   
<Text sx={{marginBottom:"0.6rem"}}>Benjamin Reiter, Musiker   // Wiesbaden</Text>    
 
<Text sx={{marginBottom:"0.6rem"}}>Karl-Heinz Schleis, Bürgermeister a.D. // Bacharach</Text> 
<Text sx={{marginBottom:"0.6rem"}}>Ricus Sebes, Studio für Keramik, Sankt Goar</Text>
<Text sx={{marginBottom:"0.6rem"}}>
Sonja Sebes-Top, Studio für Keramik, Sankt Goar 
</Text>
<Text sx={{marginBottom:"0.6rem"}}>
Astrid Stosius, Unternehmerin  // Niederheimbach &amp; Frankfurt 
</Text>
   
<Text sx={{marginBottom:"0.6rem"}}>
Fritz Stüber, Kulturaktivist  // Bacharach    
</Text>
<Text sx={{marginBottom:"0.6rem"}}>
Jürgen Zimmer, Musiker  // Bacharach    
</Text>

<br/>
<br/>
<Link sx={{color:`text`}} to="/beitritt">Mitglied werden</Link>
<br/>
<br/>

<Link sx={{color:`text`}} to="/ziele">Unsere Ziele</Link>
 </Box>
 </Container>
  </Flex>  
    </Layout>
  )
}
 
   
  

 

 


